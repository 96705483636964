<template>
  <div class="card cert-card d-flex justify-content-center">
    <div class="card-body">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import * as Api from '../api'

export default {
  name: 'naver',
  components: {},
  data() {
    return {
      query: null,
      access_token: null,
      message: '네이버에서 인증코드를 받았습니다.'
    }
  },
  created() {
    this.query = this.$route.query
    console.log('naver query:: ', this.query)
  },
  mounted() {
    this.getToken()
  },
  methods: {
    getToken() {
      if (this.query.code) {
        Api.auth
          .nlogin(this.query)
          .then(data => {
            if (data.customToken) {
              this.signInWithCustomToken(data.customToken)
            }
          })
          .catch(err => {
            //this.$q.notify({ message: err.error, color: 'negative' })
            console.log(err.error)
            this.message = '이미 등록중인 이메일입니다. 등록된 이메일로 로그인하세요.'
          })
      }
    },
    signInWithCustomToken(customToken) {
      this.message = '파이어베이스 로그인중입니다...'
      this.$firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(() => {
          // this.$q.notify('로그인되었습니다.')
          self.close()
        })
        .catch(err => {
          this.$q.notify({ message: err.message, color: 'negative' })
          console.log(err.message)
        })
    }
  }
}
</script>
<style></style>
